<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import BudgetPage from "./BudgetPage/index.vue";
import BudgetTransfers from "./BudgetTransfers/index.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    BudgetPage,
    BudgetTransfers,
  },
  data() {
    return {
      transferObject: {},
      budgetObject: {},
    };
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('budget.title')"
    />
    <b-tabs
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <!-- start budget -->
      <b-tab active>
        <template v-slot:title type="button">
          <div @click="budgetObject.getBudget()" type="button">
            <span
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="d-inline-block d-sm-none"
            >
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block">{{
              $t("budget.title")
            }}</span>
          </div>
        </template>
        <BudgetPage :budgetObject="budgetObject" />
      </b-tab>
      <!-- end budget -->

      <!-- start transfer -->
      <b-tab>
        <template v-slot:title>
          <div type="button" @click="transferObject.getTransfers()">
            <span
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="d-inline-block d-sm-none"
            >
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block">{{
              $t("budget.transfers.title")
            }}</span>
          </div>
        </template>
        <BudgetTransfers :transferObject="transferObject" />
      </b-tab>
      <!-- end transfer -->
    </b-tabs>
  </Layout>
</template>
<style scoped></style>
