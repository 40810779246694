<script>
import appConfig from "@/app.config";
// import store from "@/state/store";
// var state = store._modules.root._children.auth;

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      limit: 20,
      page: 1,
      tot_pages: 0,
      transfers: [],
    };
  },
  props: ["transferObject"],
  // computed: {
  //   ...mapGetters.auth({
  //     budgetTab1Val: "budgetTab1Val",
  //   }),
  // },
  methods: {
    getTransfers(page) {
      this.http
        .post("budget/transfers", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          if (res.status) {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.transfers = res.data;
          }
        });
    },
    getPagination() {
      console.log("loading");
      this.getTransfers(this.page);
    },
    printReport() {
      window.print();
    },
  },

  created() {
    // eslint-disable-next-line vue/no-mutating-props
    this.transferObject.getTransfers = this.getPagination;
    this.transferObject.getTransfers();
    // this.getTransfers(1);
  },
};
</script>

<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-centered table-nowrap table-striped table-hover align-middle"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">#</th>
                <th scope="col">{{ $t("budget.transfers.from") }}</th>
                <!-- <th scope="col">{{ $t("budget.transfers.particular") }}</th> -->
                <th scope="col">{{ $t("budget.transfers.to") }}</th>
                <th scope="col">{{ $t("budget.transfers.user") }}</th>
                <th scope="col">{{ $t("budget.transfers.value") }}</th>
                <th scope="col">{{ $t("budget.transfers.date") }}</th>
                <!-- <th scope="col">{{ $t("budget.transfers.balance") }}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transfer, index) in transfers" :key="transfer">
                <td>{{ index + 1 }}</td>
                <td>
                  {{
                    $i18n.locale == "ar"
                      ? transfer?.from_budget?.level_three_chart_of_account
                          ?.name
                      : transfer?.from_budget?.level_three_chart_of_account
                          ?.name_en
                  }}
                </td>
                <td>
                  {{
                    $i18n.locale == "ar"
                      ? transfer?.to_budget?.level_three_chart_of_account?.name
                      : transfer?.to_budget?.level_three_chart_of_account
                          ?.name_en
                  }}
                </td>
                <td>
                  {{ transfer?.user?.name }}
                </td>
                <td>
                  {{ parseFloat(transfer?.value).toLocaleString() }}
                </td>
                <td>
                  {{ transfer?.created?.split("T")[0] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- start pagination -->
        <ul class="pagination pagination-rounded justify-content-center mb-2">
          <li class="page-item" :class="{ disabled: page == 1 }">
            <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page - 1)"
              aria-label="Previous"
            >
              <i class="mdi mdi-chevron-left"></i>
            </a>
          </li>
          <li
            class="page-item"
            :class="{ active: p == page }"
            v-for="p in tot_pages"
            :key="p"
          >
            <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
              p
            }}</a>
          </li>

          <li class="page-item" :class="{ disabled: page == tot_pages }">
            <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page + 1)"
              aria-label="Next"
            >
              <i class="mdi mdi-chevron-right"></i>
            </a>
          </li>
        </ul>
        <!-- end pagination -->
      </div>
    </div>
  </div>
</template>
<style scoped>
/*
.table,
td {
  border: 1px solid black;
}
*/
.print {
  display: none;
}
@media print {
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }
}
</style>
