<script>
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      budget: [],
      budgetExpenses: [],
      budgetRevenues: [],
      budgets: [],
      Budget: {},
      transfer: {},
      selectedBudget: {},
      page: 1,
      limit: 20,
      level_three: [],
      tot_pages: 0,
      searchBudget: null,
      BudgetSearchMode: false,

      // appprices:[]
    };
  },
  methods: {
    editBudget() {
      console.log("____", this.Budget);
      this.http
        .put("budget", this.Budget.id, {
          value: this.Budget.value,
          level_three_chart_of_account_id:
            this.Budget.level_three_chart_of_account_id,
          year: this.Budget.year,
        })
        .then(() => {
          this.get(this.page);
        });
    },
    addBudget() {
      this.Budget.year = this.Budget.year.split("-")[0];
      console.log(this.Budget);
      this.http.post("budget", this.Budget).then(() => {
        this.Budget = {};
        this.get(this.page);
      });
    },
    search() {
      this.BudgetSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("budget/search", { search: this.searchBudget })
        .then((res) => {
          this.budget = res.data;
        });
    },
    changeBudget(event, app) {
      if (app.id) {
        const data = {
          value: event.target.value,
        };
        this.http.put("budget/", app.id, data).then((resp) => {
          if (resp.status) {
            console.log("updated");
          }
        });
      } else {
        const data = {
          level_three_chart_of_account_id: app.level_three_chart_of_account_id,
          value: event.target.value,
        };
        this.http.post("budget", data).then((resp) => {
          if (resp.status) {
            console.log("created");
            app.id = resp.data;
          }
        });
      }
    },
    startTransferProcess(budget,type) {
      this.selectedBudget = budget;
      this.selectedBudget.type = type
      console.log(budget);
    },
    completeTransfer() {
      console.log(this.transfer);
      this.http
        .post("budget/transfer", {
          fromBudget: this.selectedBudget,
          toBudget: this.transfer,
        })
        .then((res) => {
          if (res.status) {
            this.transfer = {};
            this.get(this.page);
          }
        });
    },
    getPagination() {
      this.get(this.page);
    },
    get(page) {
      console.log(page);
      this.http.post("budget/by-query", {}).then((res) => {
        this.budgets = [
          ...res.data[0]?.data?.expenses,
          ...res.data[0]?.data?.revenues,
        ];
        this.budgetExpenses = res.data[0]?.data?.expenses;
        this.budgetRevenues = res.data[0]?.data?.revenues;
      });
    },
  },
  props: ["budgetObject"],
  created() {
    // eslint-disable-next-line vue/no-mutating-props
    this.budgetObject.getBudget = this.getPagination;
    this.budgetObject.getBudget();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col table-responsive">
              <!-- revenues table -->
              <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">
                      {{ $t("budget.revenues") }}
                    </th>
                    <th scope="col">{{ $t("budget.value") }}</th>
                    <th scope="col">{{ $t("popups.operations") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="app in budgetRevenues" :key="app">
                    <td>
                      {{ $i18n.locale == "ar" ? app?.name : app?.name_en }}
                    </td>
                    <td>
                      <input
                        @change="changeBudget($event, app)"
                        type="number"
                        :value="app?.value ? app?.value : 0"
                      />
                    </td>
                    <td>
                      <button
                        :disabled="!app?.id || app?.value <= 0"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#transfer"
                        @click="startTransferProcess(app,'rev')"
                      >
                        {{ $t("popups.transfer") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col table-responsive">
              <!-- expenses table -->
              <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">
                      {{ $t("budget.expenses") }}
                    </th>
                    <th scope="col">{{ $t("budget.value") }}</th>
                    <th scope="col">{{ $t("popups.operations") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="app in budgetExpenses" :key="app">
                    <td>
                      {{ $i18n.locale == "ar" ? app?.name : app?.name_en }}
                    </td>
                    <td>
                      <input
                        @change="changeBudget($event, app)"
                        type="number"
                        :value="app?.value ? app?.value : 0"
                      />
                    </td>
                    <td>
                      <button
                        :disabled="!app?.id || app?.value <= 0"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#transfer"
                        @click="startTransferProcess(app,'exp')"
                      >
                        {{ $t("popups.transfer") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->

  <!--start add new col modal-->
  <div
    class="modal fade"
    id="transfer"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="container px-0">
            <form @submit.prevent="completeTransfer()">
              <div class="modal-header">
                <h5 class="modal-title mt-3" id="exampleModalLabel">
                  {{ $t("popups.transfer") }}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style="position: relative; left: 10px"
                ></button>
              </div>
              <div class="modal-body">
                <div class="mt-3">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("budget.from")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :value="
                      $i18n.locale == 'ar'
                        ? selectedBudget?.name
                        : selectedBudget?.name_en
                    "
                  />
                </div>
                <div class="mt-3">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("budget.select_budget")
                  }}</label>
                  <select
                    class="form-select"
                    required
                    v-model="transfer.budget"
                    aria-label="Default select example"
                  >
                    <option
                      :style="{
                        background:
                          budget.id == selectedBudget.id ? '#f1f1f1' : '',
                      }"
                      :disabled="budget.id == selectedBudget.id"
                      :value="budget"
                      v-for="budget in (selectedBudget.type=='rev' ? budgetRevenues : budgetExpenses)"
                      :key="budget"
                    >
                      {{
                        $i18n.locale == "ar" ? budget?.name : budget?.name_en
                      }}
                    </option>
                  </select>
                </div>
                <div class="my-3">
                  <label for="exampleInputPassword1" class="form-label">{{
                    $t("popups.value")
                  }}</label>
                  <input
                    type="number"
                    required
                    min="0"
                    :max="selectedBudget.value"
                    :style="{
                      borderColor:
                        parseFloat(selectedBudget.value) <
                        parseFloat(transfer.value ? transfer.value : 0)
                          ? 'red'
                          : '',
                    }"
                    v-model="transfer.value"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
              </div>
              <div
                class="modal-footer"
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              >
                <button
                  :disabled="
                    parseFloat(selectedBudget.value) <
                    parseFloat(transfer.value ? transfer.value : 0)
                  "
                  type="submit"
                  class="btn btn-primary"
                >
                  {{ $t("popups.transfer") }}
                </button>
                <button
                  data-bs-dismiss="modal"
                  type="button"
                  class="btn btn-secondary"
                >
                  {{ $t("popups.cancel") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end add new col modal-->
</template>
